import { WebLayout } from 'components/Layout';
import React from 'react';
import AboutBanner from 'assets/about-banner.png';
import LonnahAvatar from 'assets/team/lonnah.png';
import JonahAvatar from 'assets/team/jonahgeek.png';
import QoutesSvg from 'assets/qoutes.svg';

export const AboutUs = () => {
  return (
    <WebLayout>
      <div className="p-8 px-8 md:px-56">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
          <div className="text-center md:text-start">
            <div className="text-4xl league-spartan-extra-bold md:text-7xl text-black">
              About <span className="text-[#F9A200]"> Us.</span>
            </div>
            <div className="text-3xl league-spartan-bold mb-3">A Cohesive Team of Creatives.</div>
            <div className="text-xl">
              We are a small team of remote creative designers, skilled engineers, insightful
              analysts and legal tech experts. We are committed to delivering high-quality work
              swiftly and efficiently.
            </div>
          </div>
          <div className="flex flex-row justify-center md:justify-end">
            <img src={AboutBanner} alt="" className="h-64 md:h-[24vw]" />
          </div>
        </div>
      </div>

      <div className="bg-[#1E1E1E] p-8 mb-12">
        <div className="mx-auto -mt-12 bg-black text-[#F9A200] w-fit text-3xl md:text-4xl league-spartan-extra-bold px-3 mb-3">
          Meet Our Directors
        </div>
        <div className="text-white text-center text-2xl md:w-1/2 mx-auto my-12">
          We are a small team of remote designers, developers. Ashita has a main team that
          coordinates the rest remotely.
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-2 md:items-center">
          <div className="flex flex-col items-center justify-center">
            <div className="">
              <img src={LonnahAvatar} alt="" className="h-80" />
            </div>
            <div className="text-3xl text-white text-center mt-3">
              <span className="league-spartan-extra-bold">Lonnah</span> Alinda
            </div>
            <div className="text-3xl bg-[#F9A200] w-fit md:w-[22vw] text-white text-center my-2">
              <em className="league-spartan-bold text-black">Director</em>
            </div>
            <div className="text-xl text-white text-center">Cybersecurity</div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="">
              <img src={JonahAvatar} alt="" className="h-80" />
            </div>
            <div className="text-3xl text-white text-center mt-3">
              <span className="league-spartan-extra-bold">Jonathan</span> Mwebaze
            </div>
            <div className="text-3xl bg-[#F9A200] w-fit md:w-[22vw] text-white text-center my-2">
              <em className="league-spartan-bold text-black">Director</em>
            </div>
            <div className="text-xl text-white text-center">Software Engineer</div>
          </div>

          <div className="md:col-span-2 md:ml-24">
            <div className="mb-8">
              <img src={QoutesSvg} alt="" />
            </div>
            <div className="text-white text-2xl">
              Thank you for visiting Ashita, We are glad to have you here! Ashita’s main goal is to
              satisfy our end users. Whether client or just a regular user on one of our platforms,
              we design & build secure systems. We are grateful to our remote teams for making this
              a reality.
            </div>
            <div className="text-md text-end text-white mr-12 mt-4">- Jonathan</div>
          </div>
        </div>
      </div>

      <div className="mx-8 md:mx-48">
        <div className="bg-black text-[#F9A200] w-fit p-1 px-3">Our Services</div>
        <div className="my-12 text-start text-xl md:text-4xl">
          We are currently reviewing our services & pricing. No worries, you can still reach out to
          us directly through email{' '}
          <a href="mailto:ashitacreatives@gmail.com" className="text-[#F9A200]">
            ashitacreatives@gmail.com
          </a>
          . The content on this page will be availble once we've reviewed the services.
        </div>
      </div>
    </WebLayout>
  );
};
